import { useState } from 'react';
import {
    Accordion, AccordionDetails, AccordionSummary, AccordionActions, Button, Divider, Link, CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import Api from './Api';

const useStyles = makeStyles(() => ({
    root: {
        background: '#116fb9',
        marginTop: '-.35rem !important',
        borderRadius: '4px',
        "& > .Mui-expanded": { minHeight: '3rem' }
    },
    summary: {
        "& .MuiAccordionSummary-content": {
            margin: '0',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    certContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0'
    },
    noCert: {
        padding: '1rem'
    },
    certEntry: {
        display: 'flex',
        alignItems: 'center',
        padding: '.3rem 1rem .3rem 0',
        fontWeight: 600
    },
    link: {
        color: '#F8F8F9',
        textDecoration: 'none',
        "&:hover": {
            color: '#8ACBFF',
            textDecoration: 'none'
        },
    }
}));

export default ({ setSignInOpen, profile, jwt }) => {
    const classes = useStyles();
    const { logout } = Api();
    const [ loggingOut, setLoggingOut ] = useState(false);

    const performLogout = () => {
        setLoggingOut(true);
        logout();
    };

    if (jwt) {
        if (!profile) {
            return <CircularProgress size={30} />;
        } else {
            return (
                <Accordion className={classes.root}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.summary}>
                        Hey {profile.firstName}!
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails className={classes.certContainer}>
                        { !profile.badges.length ?
                            <div className={classes.noCert}>You are not certified yet</div> :
                            profile.badges.map(badge => (
                                <div key={badge.name} className={classes.certEntry}>
                                    <img src={badge.imageUrl} style={{ width: '5rem' }} alt={badge.name} />
                                    <Link className={classes.link} href={`//credly.com/badges/${badge.id}`} target="_blank">
                                        {badge.name.replace(/Symphony Certified /g,'')}
                                    </Link>
                                </div>
                            ))
                        }
                    </AccordionDetails>
                    <Divider />
                    <AccordionActions>
                        <Button variant="blue" onClick={performLogout} disabled={loggingOut}>
                            { loggingOut ? <CircularProgress size={15} /> : 'Sign Out' }
                        </Button>
                    </AccordionActions>
                </Accordion>
            );
        }
    } else {
        return (
            <Button variant="blue" onClick={() => setSignInOpen(true)}>
                Sign In
            </Button>
        );
    }
};
