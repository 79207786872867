
const UIClasses = {
  title: {
    fontSize: '24px',
    color: '#FFC840'
  },
  text: {
    fontSize: '16px'
  },
  link: {
    padding: '5px',
    cursor: 'pointer',
    color: '#F8F8F9',
    fontSize: '.9rem',
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
      textDecorationColor: '#008EFF'
    }
  },
  textfield: {
    color: '#ffffff',
    width: '400px'
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #424242 inset"
    }
  },
  label: {
    color: '#ffffff'
  },
  dialogTitle: {
    background: '#008EFF',
    color: '#ffffff',
    lineHeight: '40px',
    height:'40px',
    paddingLeft: '20px',
    margin: '2px',
    borderRadius: '4px 4px 0px 0px'
  },
  dialogBody: {
    padding: '30px'
  }
}
export default UIClasses;
