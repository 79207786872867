import { useRecoilState } from 'recoil';
import atoms from './Atoms';

export default () => {
    const baseUri = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
    const [ jwt, setJwt ] = useRecoilState(atoms.jwt);
    const [ , setProfile ] = useRecoilState(atoms.profile);
    const setStatusBar = useRecoilState(atoms.statusBar)[1];
    const showStatus = (severity, message) => setStatusBar({ open: true, severity, message });

    const jsonHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };

    const getJwtHeader = (token) => ({
        headers: { ...jsonHeaders, ...(token ? { 'Authorization': `Bearer ${token}` } : {}) }
    });

    const [ GET, POST, PUT, DELETE ] = [ 'GET', 'POST', 'PUT', 'DELETE' ];

    const apiCall = (method, path, body, tokenOverride) => {
        const fetchConfig = { method, ...getJwtHeader(tokenOverride || jwt) };
        if ([ PUT, POST ].indexOf(method) > -1 && body) {
            fetchConfig.body = JSON.stringify(body);
        }
        return fetch(`${baseUri}/api/${path}`, fetchConfig).then(async (response) => {
            if (!response.ok) {
                throw new Error((await response.json()).detail);
            }
            if (path !== 'authenticate' && response.status === 401) {
                setJwt(undefined);
                setProfile(undefined);
                window.localStorage.clear();
                showStatus('warning', 'Your session has expired. Please login again.');
            } else if (response.headers.get('Content-Type').indexOf('json') > -1) {
                return response.json();
            } else {
                return response.text();
            }
        });
    };

    const login = (credentials) =>
        apiCall(POST, 'authenticate', credentials)
            .then((response) => {
                setJwt(response.token);
                window.localStorage.setItem('jwt', response.token);
                apiCall(GET, 'profile', undefined, response.token).then((res) => setProfile(res));
                showStatus('success', 'Login successful');
                return response.token;
            })
            .catch((error) => {
                throw error;
            });

    const logout = () => {
        apiCall(GET, 'logout').then(() => {
            setJwt(undefined);
            setProfile(undefined);
            window.localStorage.clear();
            showStatus('success', 'Logout successful');
        });
    };

    const loadPersistedToken = () => {
        const storedToken = window.localStorage.getItem('jwt');
        if (storedToken) {
          setJwt(storedToken);

          apiCall(GET, 'profile', undefined, storedToken)
            .then((res) => {
                setProfile(res);
                apiCall(POST, 'authenticate/refresh', undefined, storedToken).then((response) => {
                    setJwt(response.token);
                    window.localStorage.setItem('jwt', response.token);
                });
            })
            .catch(() => {
              setJwt(undefined);
              window.localStorage.clear();
            });
        }
    };

    return {
        showStatus,
        login,
        logout,
        loadPersistedToken,
        getConfig: () => apiCall(GET, 'config'),
        getEvents: () => apiCall(GET, 'events'),
        getThinkificToken: (jwt) => apiCall(GET, 'thinkific-token', {}, jwt),
        getScooldToken: (jwt) => apiCall(GET, 'scoold-token', {}, jwt),
        getCountries: () => apiCall(GET, 'countries'),
        submitRegistration: (payload) => apiCall(POST, 'register', payload),
        submitForgotPassword: (payload) => apiCall(POST, 'forgot', payload),
        validateForgotToken: (payload) => apiCall(POST, 'validate-forgot', payload),
        submitResetPassword: (payload) => apiCall(POST, 'reset', payload),
        getDevCenterConversion: () => apiCall(GET, 'admin/dev-center-conversion'),
        getTimeSeries: () => apiCall(GET, 'admin/time-series'),
        searchStudents: (payload) => apiCall(POST, 'admin/search-students', payload),
        getStudent: (id) => apiCall(GET, `admin/student/${id}`),
        getAdminThinkificToken: (id) => apiCall(GET, `admin/thinkific-token/${id}`),
        getAdminScooldToken: (id) => apiCall(GET, `admin/scoold-token/${id}`),
        saveEvent: (payload) => apiCall(POST, 'admin/event', payload),
        deleteEvent: (id) => apiCall(DELETE, `admin/event/${id}`),
        proservInquiry: (payload) => apiCall(POST, 'proserv/inquiry', payload),
    };
};
