import Api from './Api';
import { useRecoilState } from 'recoil';
import atoms from './Atoms';

export default () => {
    const [ jwt ] = useRecoilState(atoms.jwt);
    const [ config ] = useRecoilState(atoms.config);

    const {
        getThinkificToken: thinkific,
        getScooldToken: scoold,
        getAdminThinkificToken: thinkificAdmin,
        getAdminScooldToken: scooldAdmin,
    } = Api();
    const tokenApis = { thinkific, scoold, thinkificAdmin, scooldAdmin };
    const nameMap = { 'training-center': 'thinkific', 'forum': 'scoold' };

    const getUri = (site) => {
        const base = config[site];
        switch(site) {
            case 'thinkific':
                const returnUri = encodeURIComponent(`${base}/enrollments`);
                return `${base}/api/sso/v2/sso/jwt?return_to=${returnUri}&jwt=`;
            case 'scoold':
                return `${base}/signin/success?passwordless=true&jwt=`;
        }
    };

    return {
        launchSso: (
            name, setLoading = () => {}, sameWindow = false, jwtOverride, userId,
        ) => {
            const site = nameMap[name];
            const token = jwtOverride || jwt;
            if (!token) {
                window.open(config[site], '_blank').focus();
            } else {
                setLoading(true);
                const endpoint = userId ? tokenApis[site + 'Admin'](userId) :
                    tokenApis[site](token);
                endpoint.then((response) => {
                    const uri = `${getUri(site)}${response.token}`;
                    if (sameWindow) {
                        window.location.href = uri;
                    } else {
                        window.open(uri, '_blank').focus();
                    }
                    setLoading(false);
                });
            }
        },
    };
};
