import { atom } from 'recoil';

export default {
    config: atom({ key: 'config', default: {} }),
    jwt: atom({ key: 'jwt', default: undefined }),
    profile: atom({ key: 'profile', default: undefined }),
    statusBar: atom({ key: 'statusBar', default: { open: false } }),
    timeSeriesData: atom({ key: 'timeSeriesData', default: undefined }),
    conversionData: atom({ key: 'conversionData', default: undefined }),
    events: atom({ key: 'events', default: [] }),
};
