import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';
import { RecoilRoot } from 'recoil';
import CircularProgress from '@mui/material/CircularProgress';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';

const renderLoader = () => (
  <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <CircularProgress size={70} />
  </div>
);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={renderLoader()}>
        <RecoilRoot>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </RecoilRoot>
      </Suspense>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.querySelector('#root'),
);

serviceWorkerRegistration.register({
  onSuccess: () => {},
  onUpdate: (registration) => {
      const registrationWaiting = registration.waiting;
      if (registrationWaiting) {
          registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
          registrationWaiting.addEventListener('statechange', (e) => {
              if (e.target.state === 'activated') {
                  window.location.reload();
              }
          });
      }
  },
});
