import React, { lazy, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Route, Routes, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import atoms from './components/Atoms';
import Api from './components/Api';
import Navbar from './components/NavBar';

const StatusBar = lazy(() => import('./components/StatusBar'));
const Copyright = lazy(() => import('./components/Copyright'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const ForgotPassword = lazy(() => import('./components/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const Community = lazy(() => import('./components/community/Community'));
const ProServ = lazy(() => import('./components/proserv/ProServ'));
const Events = lazy(() => import('./components/community/Events'));
const SymphonyUri = lazy(() => import('./components/SymphonyUri'));
const Homepage = lazy(() => import('./components/homepage/Homepage'));
const Admin = lazy(() => import('./components/admin/Admin'));

const AppContainer = styled('div')`
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

export default function App() {
  const { loadPersistedToken, getConfig } = Api();
  const [ , setConfig ] = useRecoilState(atoms.config);
  const location = useLocation();
  const [ showTemplate, setShowTemplate ] = useState(false);

  useEffect(() => {
    setShowTemplate(!location.pathname.startsWith('/chat'));
    loadPersistedToken();
    getConfig()
      .then((response) => {
        setConfig(response);
      })
      .catch((error) => console.log(error));
  }, []);

  const marginProps = showTemplate && {
    marginTop: { xs: '3rem', md: '7rem' }
  };

  return (
    <AppContainer sx={marginProps}>
      { showTemplate && <Navbar /> }
      <Routes>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="register-osff" element={<Register />} />
        <Route path="register-osff-demo" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset" element={<ResetPassword />} />
        <Route path="community" element={<Community />} />
        <Route path="events" element={<Events />} />
        <Route path="pro-serv" element={<ProServ />} />
        <Route path="admin/*" element={<Admin />} />
        <Route path="chat/*" element={<SymphonyUri />} />
        <Route path="*" element={<Homepage />} />
      </Routes>
      { showTemplate && <Copyright /> }
      <StatusBar />
    </AppContainer>
  );
}
