import { useState } from 'react';
import { Link as RouterLink, useMatch, useNavigate } from "react-router-dom";
import {
  AppBar, IconButton, Link, Menu, MenuItem, Toolbar, Typography, CircularProgress, Button,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from "@mui/icons-material/Menu";
import { useRecoilState } from 'recoil';
import atoms from './Atoms';
import SignInButton from './SignInButton';
import Api from './Api';
import SSO from './SSO';
import LoginBackdrop from './LoginBackdrop';

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundImage: 'linear-gradient(265.23deg, #393D48 3.73%, #292C34 36.28%, #191A1F 74.74%, #0E0F11 96.02%)',
    fontFamily: '"Graphik", Sans-serif',
    letterSpacing: '0.02rem',
    color: '#F8F8F9',
    borderBottom: '1px solid #626671'
  },
  preToolbar: {
    borderBottom: '1px solid #626671',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logo: {
    marginRight: '15px',
    width: '18px',
    height: '18px'
  },
  beta: {
    color: '#008eff',
    fontSize: '10px'
  },
  titleGroup: {
    display: 'flex',
    margin: '.3rem 1rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  mobileTitleGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  title: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#ffffff'
  },
  mainMenuContainer: {
    padding: '0 1.1rem',
    display: 'flex',
    minHeight: '4rem',
    height: '4rem',
    alignItems: 'flex-start',
    paddingTop: '.9rem'
  },
  mainMenu: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    flexGrow: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    paddingTop: '.5rem'
  },
  altMenu: {
    fontSize: '11px',
    lineHeight: '25px'
  },
  link: {
    padding: '0',
    cursor: 'pointer',
    color: '#F8F8F9',
    textDecoration: 'none',
    "&:hover": {
      color: '#8ACBFF',
      textDecoration: 'none'
    },
    "&:not(:last-child)::after": {
      content: '"|"',
      padding: '0 .5rem',
      color: '#626671',
      "&:hover": {
        color: '#626671'
      }
    },
    "& div": { marginLeft: '.5rem' }
  },
  mobileLink: {
    "&:hover": {
      textDecoration: 'none'
    },
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#444956',
    textDecoration: 'none'
  },
  loading: {
    color: '#8ACBFF',
    cursor: 'wait'
  },
  borderTopDivider: {
    borderTop: '#626671 1px solid',
  },
  loggedIn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '.6rem',
    "&:hover": {
      cursor: 'default',
      backgroundColor: 'inherit'
    },
    "& .MuiTouchRipple-root": {
      display: 'none'
    },
    "& > span": {
      padding: '.5rem 0 1rem 0'
    }
  },
  adminButton: {
    border: '2px solid #F69120',
    borderRadius: '6px',
    "&:hover": {
        background: '#F69120'
    }
  },
  miniButton: {
    padding: 0,
    height: '1.5rem'
  }
}));

const navBarItems = [
  { name: 'Documentation', href: '//docs.developers.symphony.com' },
  { name: 'Training Center', ssoSite: 'training-center' },
  { name: 'Forum', ssoSite: 'forum' },
  { name: 'Community', to: '/community' },
  { name: 'Professional Services', to: '/pro-serv' },
];

const AdminButton = ({ setAnchor, isMobile }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const goToAdmin = () => {
    navigate('/admin/dashboard');
    if (setAnchor) {
      setAnchor(null);
    }
  };
  return (
    <Button variant="blue" className={`${classes.adminButton} ${!isMobile && classes.miniButton}`} onClick={goToAdmin}>
      Admin
    </Button>
  );
};

const DesktopNavBarLink = ({ name, href, to, ssoSite, launchSso }) => {
  const classes = useStyles();
  const [ loading, setLoading ] = useState(false);
  return (
    href ?
      <Link className={classes.link} href={href} target="_blank" rel="noopener">{name}</Link> :
    to ?
      <RouterLink className={classes.link} to={to}>{name}</RouterLink> :
    (
      <Link className={`${classes.link} ${loading ? classes.loading : ''}`} onClick={() => launchSso(ssoSite, setLoading)}>
        {name} { loading ? <CircularProgress size={15} /> : '' }
      </Link>
    )
  );
};

const DesktopNavBar = ({ jwt, profile, isMobile, launchSso }) => {
  const classes = useStyles();
  const isLoginPage = useMatch('/login');
  const isAdminPage = useMatch('/admin/*');
  const [ signInOpen, setSignInOpen ] = useState(false);

  return (
    <>
      <div className={classes.preToolbar}>
        <div className={classes.titleGroup}>
          <RouterLink to="/" className={classes.logoLink}>
            <img src="/symphony-logo.png" className={classes.logo} alt="Home" />
            <Typography color="inherit" className={classes.title}>
              Symphony Developer Center <span className={classes.beta}>(beta)</span>
            </Typography>
          </RouterLink>
          { jwt && profile && profile.admin && !isAdminPage && <AdminButton isMobile={isMobile} />}
        </div>
      </div>
      <Toolbar className={classes.mainMenuContainer}>
        <span className={classes.mainMenu}>
          { navBarItems.map((item) => <DesktopNavBarLink key={item.name} {...item} launchSso={launchSso} />) }
        </span>
        { !isLoginPage && (
          <>
            <SignInButton setSignInOpen={setSignInOpen} profile={profile} jwt={jwt} />
            <LoginBackdrop signInOpen={signInOpen} setSignInOpen={setSignInOpen} />
          </>
        )}
      </Toolbar>
    </>
  );
};

const MobileNavBar = ({ jwt, profile, launchSso }) => {
  const classes = useStyles();
  const { logout } = Api();
  const [ loggingOut, setLoggingOut ] = useState(false);
  const [ anchor, setAnchor ] = useState(null);
  const open = Boolean(anchor);
  const handleMenu = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };
  const performLogout = () => {
    setLoggingOut(true);
    logout();
  };

  return (
    <div className={classes.mobileTitleGroup}>
      <RouterLink to="/" className={classes.logoLink}>
        <img src="/symphony-logo.png" className={classes.logo} alt="Home" />
      </RouterLink>
      <Typography color="inherit" className={classes.title}>
        Developer Center
      </Typography>
      <IconButton onClick={handleMenu} aria-label="Menu" size="large">
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchor}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={handleClose}
      >
        {
          navBarItems.map(({ name, href, to, ssoSite }) => {
            const component = to ? RouterLink : Link;
            let itemProps = {};
            if (href) {
              itemProps = { href, target: '_blank', rel: 'noopener' }
            } else if (to) {
              itemProps = { to }
            } else if (ssoSite) {
              itemProps = { onClick: () => { launchSso(ssoSite); setAnchor(null); } }
            }
            return (
              <MenuItem
                key={name}
                onClick={() => setAnchor(null)}
                component={component}
                className={classes.mobileLink}
                {...itemProps}
              >
                <Typography variant="h6">{name}</Typography>
              </MenuItem>
            );
          })
        }
        {
          jwt ? (
            <MenuItem className={`${classes.loggedIn} ${classes.borderTopDivider}`}>
              <Typography variant="subtitle1">
                { profile ? `Hey ${profile.firstName}!` : <CircularProgress size={15} /> }
              </Typography>
              { (profile && profile.admin) && <AdminButton setAnchor={setAnchor} /> }
              <Button variant="blue" onClick={performLogout} disabled={loggingOut}>
                { loggingOut ? <CircularProgress size={15} /> : 'Sign Out' }
              </Button>
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => setAnchor(null)}
              component={RouterLink}
              to="/login"
              className={`${classes.mobileLink} ${classes.borderTopDivider}`}
            >
              <Typography variant="h6">Sign In</Typography>
            </MenuItem>
          )
        }
      </Menu>
    </div>
  );
};

export default () => {
  const classes = useStyles();
  const [ jwt ] = useRecoilState(atoms.jwt);
  const [ profile ] = useRecoilState(atoms.profile);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { launchSso } = SSO();
  const navbarProps = { jwt, profile, isMobile, launchSso };

  return (
    <AppBar position="fixed" className={classes.appBar}>
      { isMobile ? <MobileNavBar {...navbarProps} /> : <DesktopNavBar {...navbarProps} /> }
    </AppBar>
  );
};
