import { lazy } from 'react';
import { Backdrop, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import UIClasses from './UIClasses';

const Login = lazy(() => import('./Login'));

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0',
    width: '30rem',
    background: '#17181b',
    border: '1px #008EFF solid'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dialogTitle: UIClasses.dialogTitle,
}));

export default ({ signInOpen, setSignInOpen }) => {
  const classes = useStyles();

  const closeSignIn = (target) => {
    if (target.classList.contains('MuiBackdrop-root')) {
      setSignInOpen(false);
    }
  };

  return (
    <Backdrop
      className={classes.backdrop}
      open={signInOpen}
      onClick={(e) => closeSignIn(e.target)}
    >
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.dialogTitle}>Sign In</div>
        <Login setSignInOpen={setSignInOpen} />
      </Paper>
    </Backdrop>
  );
};
