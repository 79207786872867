import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: { mode: 'dark' },
  typography: { fontFamily: '"Graphik Regular", Sans-serif' },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html { min-height: 100% }
        body {
          background-image: linear-gradient(265.23deg, #393D48 3.73%, #292C34 36.28%, #191A1F 74.74%, #0E0F11 96.02%);
          overflow-y: scroll;
        }
      `
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'white' },
          style: `border: 2px solid #FFF`
        },
        {
          props: { variant: 'blue' },
          style: `
            color: #fff;
            background: none;
            padding: 0.4rem 0.7rem;
            border: 2px solid #008EFF;
            border-radius: 6px;
            &:hover { background: #008EFF };
          `
        },
        {
          props: { variant: 'selected' },
          style: `background: #348FFF`
        }
      ],
    }
  }
});

export default theme;
